<!-- 视图 -->
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{ this.routeSign == 'add' ? $t('ADD_PURCHASE_CONTRACT')
                        : $t('EDIT_PURCHASE_CONTRACT')
                }}</div>
            </div>
            <template>
                <div>
                    <el-form :model="formObj" ref="refForm" :rules="formObjRules" label-position="top"
                        v-bind:show-message="notip">
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Contract_NO')" prop="Code" label-width="120px"
                                    :rules="formObjRules.Code" >
                                    <el-input v-model="formObj.Code" style="width: calc(100% - 115px);" maxlength="64" :disabled="isEditLoad">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Supplier_')" prop="Supplier" label-width="120px"
                                    :rules="formObjRules.Supplier">
                                    <el-select v-model="formObj.Supplier" filterable style="width: calc(100% - 115px);"
                                        ref='Sd_Supplier' clearable placeholder="" :disabled="isEditLoad">
                                        <el-option v-for="item in SupplierData" :key="item.id" :label="item.supplierName"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Contract_Type')" prop="Type" label-width="120px"
                                    :rules="formObjRules.Type">
                                    <el-select v-model="formObj.Type" filterable style="width: calc(100% - 115px);"
                                        ref='Sd_Type' clearable placeholder="">
                                        <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Signing_Address')" prop="SignAddress"
                                    :rules="formObjRules.SignAddress" label-width="120px">
                                    <el-input v-model="formObj.SignAddress" style="width: calc(100% - 115px);"
                                        maxlength="64">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Signing_Date')" prop="SignDate" label-width="120px"
                                    :rules="formObjRules.SignDate">
                                    <el-date-picker v-model="formObj.SignDate" style="width: calc(100% - 115px);"
                                        clearable type="date" value-format="yyyy-MM-dd" placeholder=" ">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Effective_Date')" prop="EffectiveDate" label-width="120px">
                                    <el-date-picker v-model="formObj.EffectiveDate" type="daterange" align="right"
                                        unlink-panels range-separator="-" start-placeholder="" end-placeholder=""
                                        value-format="yyyy-MM-dd" style="width: calc(100% - 115px);">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-divider content-position="left">{{ $t('Other') }}</el-divider>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Currency')" prop="Currency" label-width="120px"
                                    :rules="formObjRules.Currency">
                                    <el-select v-model="formObj.Currency" ref='Sd_Currency' filterable
                                        style="width: calc(100% - 115px);" clearable placeholder="">
                                        <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Conversion_Rate')" prop="ConversionRate" label-width="120px"
                                    :rules="formObjRules.ConversionRate">
                                    <el-input-number v-model="formObj.ConversionRate" controls-position="right"
                                        style="width: calc(100% - 115px);"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Tax_Category')" prop="TaxCategory" label-width="120px"
                                    :rules="formObjRules.TaxCategory">
                                    <el-select v-model="formObj.TaxCategory" filterable ref='Sd_TaxCategory'
                                        style="width: calc(100% - 115px);" clearable placeholder=""
                                        @change="Cg_TaxCategory">
                                        <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Tax_Rate')" prop="TaxesAndCharges" label-width="120px">
                                    <el-input v-model="formObj.TaxesAndCharges" style="width: calc(100% - 115px);"
                                        maxlength="64" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Tax_Total')" prop="TotalTaxesAndCharges" label-width="120px">
                                    <el-input v-model="formObj.TotalTaxesAndCharges" style="width: calc(100% - 115px);"
                                        maxlength="64" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Total')" prop="Total" label-width="120px">
                                    <el-input v-model="formObj.Total" style="width: calc(100% - 115px);" maxlength="64"
                                        :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                         <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Set_The_Price_Algorithm')" prop="IsIncludeTax"
                                    label-width="120px">
                                    <el-switch v-model="formObj.IsIncludeTax" :active-text="$t('Tax_Inclusive')"
                                        :inactive-text="$t('Tax_Exclusive')" @change="Cg_IsIncludeTax">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Remark')" prop="Remark" label-width="120px">
                                    <el-input v-model="formObj.Remark" style="width: calc(100% - 115px);"
                                        maxlength="64"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- :show-summary="true" :sum-text="$t('Total')"-->
                        <div class="smallTit">{{ $t('Lines') }}</div>
                        <el-table :data="formObj.itemTable" :row-class-name="tableRowClassName" height="420"
                            style="width: 100%" @current-change="handleCurrentChange" :header-cell-class-name="starAdd">
                            <el-table-column v-if="false">
                                <span>{{ scope.row.Id }}</span>
                            </el-table-column>
                            <el-table-column :label="$t('Index')" type="index" width="50px" align='center'>
                            </el-table-column>
                            <el-table-column :label="$t('OPERATION')" width="110" align='center' v-if="!isLocked">
                                <template slot-scope="scope">
                                    <el-button @click="deleteItem(scope.$index, scope.row)" :title="$t('Delete')"
                                        type="danger" size="small" icon="el-icon-delete" circle></el-button>
                                    <el-button @click="addItem(scope.$index, scope.row)" :title="$t('Add')"
                                        type="primary" size="small" icon="el-icon-plus" circle></el-button>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('PRODUCT_CODE')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.ProductCode }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.ProductCode'"
                                        :rules="formObjRules.ProductCode">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.ProductCode" filterable
                                            clearable placeholder="" @change="Cg_ProductCode(scope.row)">
                                            <el-option v-for="item in ProductData" :key="item.id" :label="item.name"
                                                :value="item.id"> 
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('PRODUCT_NAME')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.ProductName }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.ProductName'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.ProductName" clearable
                                            :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('COLOR_')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Color }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Color'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Color" clearable>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('UNIT_')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.UomName }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Uom'"
                                        :rules="formObjRules.Uom">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.Uom" filterable clearable
                                            placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('QUANTITY_')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Qty }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Qty'"
                                        :rules="formObjRules.Qty">
                                        <el-input-number v-if="scope.row.isEgdit" v-model="scope.row.Qty"
                                            @change="Cg_RowAmount(scope.row)" controls-position="right" clearable>
                                        </el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('UNIT_PRICE')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Rate }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Rate'"
                                        :rules="formObjRules.Rate">
                                        <el-input-number v-if="scope.row.isEgdit" v-model="scope.row.Rate"
                                            @change="Cg_RowAmount(scope.row)" controls-position="right" clearable>
                                        </el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('TAX_TOTAL')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.TotalTaxesAndCharges }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.TotalTaxesAndCharges'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.TotalTaxesAndCharges"
                                            clearable :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('TOTAL')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Amount }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Amount'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Amount" clearable
                                            :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('REMARK')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Remark }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Remark'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Remark" clearable>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" :disabled="isSaveDisable" @click="submitForm" v-if="!isLocked">{{
                                    $t('Save')
                            }}</el-button>
                            <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
                        </div>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>

<!-- 样式 -->
<style>
.el-dialog__wrapper {
    transition-duration: 0.3s;
}

.notifyStyle {
    width: 30% !important;
    background: #FFE4E1;
}

table th.star div::before {
    content: ' * ';
    color: red;
}

.el-row_style {
    display: flex;
    flex-wrap: wrap;
    height: 90px;
}

.el-col_style {
    height: 45px;
}

.el-form--label-top .el-form-item__label {
    float: none;
    /*  display: inline-block;*/
    text-align: left;
    padding: 0 0 0px;
}
</style>
<!-- 逻辑js -->
<script>
//引入对象
import { PurchaseContractIList, PurchaseContractIById, PurchaseContractIAdd, PurchaseContractIEdit, PurchaseContractIDel } from "@/api/Purchase/PurchaseContractApi";
import { PurchaseContractItemIList, PurchaseContractItemIById, PurchaseContractItemIAdd, PurchaseContractItemIEdit, PurchaseContractItemIDel } from "@/api/Purchase/PurchaseContractItemApi";
import { TPurchaseUnifiedIsBool, getDateYYYMMDD } from "@/api/unified";
import { SupplierList } from "@/api/supplier/supplierInfo";
export default {
    //data：返回实体对象
    data() {
        var VSupplier = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                this.notiplist.push(this.$t('Supplier_') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }
        };
        var VTaxCategory = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                this.notiplist.push(this.$t('Tax_Category') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }
        };
        var VCurrency = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                this.notiplist.push(this.$t('Currency') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }
        };

        var VSignDate = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                this.notiplist.push(this.$t('Signing_Date') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }
        };
        var VType = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                this.notiplist.push(this.$t('Contract_Type') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }
        };
        var VCode = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                this.notiplist.push(this.$t('Contract_NO') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else {
                TPurchaseUnifiedIsBool("ContractExist", this.routeId, value).then(res => {
                    var IsExist = res.data;
                    if (!IsExist) {
                        this.notiplist.push(this.$t('Contract_NO') + ':' + this.$t('Already_Exist'));
                        callback(new Error(this.$t('Already_Exist')));
                    } else { callback(); }
                }).catch((erro) => { console.log(erro) });
            }
        };

        var VConversionRate = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                this.notiplist.push(this.$t('ConversionRate') + ':' + this.$t('NeedGreater0'));
                callback(new Error(this.$t('NeedGreater0')));
            }
        };
        var VProductCode = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                let indexRule = rule.fullField.split(".")
                this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('Product_Code') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }

        };
        var VUom = (rule, value, callback) => {
            if (value == '' || value == undefined || value == null) {
                let indexRule = rule.fullField.split(".")
                this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('Unit') + ':' + this.$t('Required'));
                callback(new Error(this.$t('Required')));
            } else { callback(); }

        };
        var VQty = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                let indexRule = rule.fullField.split(".")
                this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('Quantity') + ':' + this.$t('NeedGreater0'));
                callback(new Error(this.$t('NeedGreater0')));
            }
        };

        var VRate = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                let indexRule = rule.fullField.split(".")
                this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('Unit_Price') + ':' + this.$t('NeedGreater0'));
                callback(new Error(this.$t('NeedGreater0')));
            }
        };
        return {
            //路由传递信息
            routeSign: '', routeId: '',
            //错误信息弹窗
            notip: true, notipInfo: "", notiplist: [], notificationPromise: Promise.resolve(),
            //是否显示(待补充逻辑)
            isSaveDisable: false, isLocked: false, isEditLoad: false,
            //表单信息
            formObj: {

                //表头信息
                Supplier: '', Currency: '', ConversionRate: null, TaxCategory: '', TaxesAndCharges: null, TotalTaxesAndCharges: 0, SignDate: null, SignAddress: "",
                Code: '', DepartmentId: '', VendorSectionId: '', ContractId: '', AcDate: null, Remark: "", Total: 0, EffectiveDate: ['', ''],IsIncludeTax: true,
                TypeName: "",CustomerName: "", CurrencyName: "",TaxCategoryName: "",
                Docstatus: 0, ApproveState: 110, Id:"",
                //表体信息
                itemTable: [{
                    isEgdit: true, Id: "", ProductCode: "", SupplierPartNo: "", ProductName: "", Qty: 0, Uom: "", UomName: "", Rate: 0, Total: 0, Color: "", TotalTaxesAndCharges: 0, Remark: "", Status: 0,
                    ContractId:"",IndexNumber:0,
              }],
            },
            formObjRules: {
                Supplier: [{ required: true, validator: VSupplier, trigger: 'blur' }],
                Currency: [{ required: true, validator: VCurrency, trigger: 'blur' }],
                ConversionRate: [{ required: true, type: "number", validator: VConversionRate, trigger: 'blur' }],
                TaxCategory: [{ required: true, validator: VTaxCategory, trigger: 'blur' }],
                Code: [{ required: true, validator: VCode, trigger: 'blur' }],
                Type: [{ required: true, validator: VType, trigger: 'blur' }],
                SignDate: [{ required: true, validator: VSignDate, trigger: 'blur' }],
                ProductCode: [{ validator: VProductCode, trigger: 'blur' }],
                Qty: [{ type: "number", validator: VQty, trigger: 'blur' }],
                Uom: [{ validator: VUom, trigger: 'blur' }],
                Rate: [{ type: "number", validator: VRate, trigger: 'blur' }],
            },
            SupplierData: [],//供应商列表 
            DepartmentData: [],//部门列表
            CurrencyData: [], //币值列表
            TaxCategoryData: [],//税种列表
            ProductData: [{
                    id: '物料1',
                    name: '物料1',
                },{
                    id: '物料2',
                    name: '物料2',
                }],//产品列表
            UomData: [],//计量单位列表
            testData: [
                {
                    value: '测试选项1',
                    label: '测试选项1',
                    num: 1
                }, {
                    value: '测试选项2',
                    label: '测试选项2',
                    num: 2
                }, {
                    value: '测试选项3',
                    label: '测试选项3',
                    num: 3
                }, {
                    value: '测试选项4',
                    label: '测试选项4',
                    num: 4
                }, {
                    value: '测试选项5',
                    label: '测试选项5',
                    num: 5
                }],
        }
    },
    //获取(dom✓/data✓/methods✓) computed:计算属性，是根据依赖关系进行缓存的计算，只有在它的相关依赖发生改变时才会进行更新
    computed: {
        setData() {
            return {
            }
        }
    },
    //beforeCreate（创建前）:获取(dom×/data×/methods×)--> created（创建后）:获取(dom×/data✓/methods✓)-->  beforeMount（挂载前）:获取(dom×/data✓/methods✓)--> mounted（挂载后）:获取(dom✓/data✓/methods✓)-->...
    //created：data、mounted、watch等已经完成初始化，但是el dom树还未挂载
    created() {
        this.init()
    },
    //watch:用于监听data里面的数据是否被修改，一旦修改就可以执行一些其他的操作
    watch: {
        //监听路由对象是否变化
        $route() {
            this.init()
        }
    },
    //methods:方法函数
    methods: {
        //初始加载数据
        init() {
            this.routeSign = this.$route.query.sign;
            this.routeId = this.$route.query.ID;
            this.formObj.Id=this.routeId;
            //获取供应商列表
            SupplierList().then(res => {
                this.SupplierData = res.data;
            });
             //获取物料
            // getProduct().then(res => {
            //     this.ProductData = res.data.data;
            // });
            if (this.routeSign == 'edit') {
                this.isEditLoad = true;

                this.getLoadForm();
            }
        },
        //获取信息
        async getLoadForm() {
            await PurchaseContractIById(this.routeId).then(res => {
                var infodata = res.data;
                this.formObj = infodata;
                let dateArray = new Array();
                dateArray.push(infodata.BeginDate, infodata.EndDate);
                this.$set(this.formObj, "EffectiveDate", dateArray);
                this.formObj.itemTable = infodata.itemDetails;
                if (this.formObj.itemTable.length == 0) { this.addrow(); }
            }).catch((erro) => { console.log(erro) });
        },
        //新增行
        addItem(index, row) {
            let item =
            {
                isEgdit: false, Id: "", ProductCode: "", SupplierPartNo: "", ProductName: "", Qty: 0, Uom: "", UomName: "", Rate: 0, Amount: 0, Color: "", TotalTaxesAndCharges: 0, Remark: "",Status: 0,
                ContractId:"",IndexNumber:0,
            };
            this.formObj.itemTable.splice(index+1, 0, item)
        },
        addrow() {
            let item = {
                isEgdit: false, Id: "", ProductCode: "", SupplierPartNo: "", ProductName: "", Qty: 0, Uom: "", UomName: "", Rate: 0, Amount: 0, Color: "", TotalTaxesAndCharges: 0, Remark: "",Status: 0,
                ContractId:"",IndexNumber:0,
            }
            this.formObj.itemTable.push(item);
        },
        //删除子项
        deleteItem(index, row) {
            if (row.Id == '') {
                this.formObj.itemTable.splice(index, 1);
            }
            else {
                var tipInfo = this.$t('IsDel')+":" + row.ProductCode;
                var Islast = false;
                //判断是否删除
                if (this.formObj.itemTable.length == 2 && (this.formObj.itemTable[0].Id == '' || this.formObj.itemTable[1].Id == '')) {
                    tipInfo = this.$t('When the last detail is deleted, the system will automatically delete the whole order');
                    Islast = true;
                }
                this.$confirm(tipInfo, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    PurchaseContractItemIDel(row.Id).then(() => {
                        //待删除
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        this.formObj.itemTable.splice(index, 1);
                        this.Cg_Amount();
                        if (Islast) {
                            this.$router.push({ path: '/textile_Purchase/PO_Contract' });
                        }
                    });
                })
            }
        },
        //提交数据
        submitForm() {
            this.$refs["refForm"].clearValidate() // 移除校验结果
            for (var j = this.formObj.itemTable.length - 1; j > -1; j--) {
                if (this.formObj.itemTable[j].ProductCode.trim() == '' && this.formObj.itemTable[j].Uom.trim() == ''
                    && this.formObj.itemTable[j].Color == '' && this.formObj.itemTable[j].Remark.trim() == '' && (this.formObj.itemTable[j].Qty == 0 || this.formObj.itemTable[j].Rate == '')) {
                    this.formObj.itemTable.splice(j, 1);
                }
            }
            if (this.formObj.itemTable.length == 0) {
                this.addrow();
                this.$message({
                    message: this.$t('No Data Submitted'),
                    type: "error"
                });//提示无数据
                return false;
            } else {
                this.$nextTick(() => {//避免点击两次
                    this.notip = false;
                    this.notiplist = [];
                    let formName = "refForm";
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.isSaveDisable = true;
                            this.saveINFO();
                        } else {
                            this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                                let newDatas = [];
                                const h = this.$createElement;
                                for (let i in this.notiplist) {
                                    newDatas.push(h('p', null, this.notiplist[i]));
                                }
                                this.$notify.error({
                                    title: this.$t('OperationFailed'),
                                    offset: 100,
                                    showClose: true,
                                    duration: 0,
                                    message: h('div', null, newDatas),
                                    customClass: 'notifyStyle',
                                });
                            });
                            return false;
                        }
                    });
                });
            }
        },
        //保存订单信息
        saveINFO() {
            this.formObj.TypeName= this.$refs.Sd_Type.selectedLabel;
            this.formObj.SupplierName=  this.$refs.Sd_Supplier.selectedLabel;
            this.formObj.CurrencyName=  this.$refs.Sd_Currency.selectedLabel;
            this.formObj.TaxCategoryName=  this.$refs.Sd_TaxCategory.selectedLabel;
            this.formObj.BeginDate=  this.formObj.EffectiveDate[0];
            this.formObj.EndDate=  this.formObj.EffectiveDate[1];
            if (this.routeId != '') {
                PurchaseContractIEdit(this.formObj).then(() => {
                    this.formObj.itemTable.forEach(v => {
                        v.ContractId=this.routeId;
                        v.IndexNumber= v.index;
                        if (v.Id != '') {
                            PurchaseContractItemIEdit(v).then().catch((erro) => { console.log(erro) });
                        }
                        if (v.Id == '') {
                            PurchaseContractItemIAdd(v).then().catch((erro) => { console.log(erro) });
                        }
                    })
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    //返回列表
                    this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                    this.$router.push({ path: '/textile_Purchase/PO_Contract' });
                }).catch((erro) => { console.log(erro) });
            }
            if (this.routeId == '') {
                PurchaseContractIAdd(this.formObj).then(res => {
                    this.formObj.itemTable.forEach(v => {
                        v.ContractId=res.data.result;
                        v.IndexNumber= v.index;
                        if (v.Id != '') {
                            PurchaseContractItemIEdit(v).then().catch((erro) => { console.log(erro) });
                        }
                        if (v.Id == '') {
                            PurchaseContractItemIAdd(v).then().catch((erro) => { console.log(erro) });
                        }
                    })
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    //返回列表
                    setTimeout(() => {
                        this.isSaveDisable = false;
                        this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                        this.$router.push({ path: '/textile_Purchase/PO_Contract' });
                    }, 1000 * Math.random());
                }).catch((erro) => { console.log(erro) });
            }
        },
        //取消按钮
        resetForm() {
            this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
            this.$router.push({ path: '/textile_Purchase/PO_Contract' });
        },
        //值改变-产品编码
        Cg_ProductCode(row) {
            if (row.ProductCode != "") {
                var objrow1 = {};
                objrow1 = this.ProductData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.id === row.ProductCode;//筛选出匹配数据
                });
                row.ProductName = objrow1.name;
            } else {
                row.ProductName = "";
            }
        },
        //值改变-计量单位
        Cg_Uom(row) {
            if (row.Uom != "") {
                var objrow1 = {};
                objrow1 = this.testData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === row.Uom;//筛选出匹配数据
                });
                row.UomName = objrow1.label;
            } else {
                row.UomName = "";
            }
        },
        //值改变-单价含税计算
        Cg_IsIncludeTax() {
            this.Cg_Amount();
        },
        //值改变-税种类别  
        Cg_TaxCategory() {
            if (this.formObj.TaxCategory != "") {
                var objrow1 = {};
                objrow1 = this.testData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === this.formObj.TaxCategory;//筛选出匹配数据
                });
                this.formObj.TaxesAndCharges = objrow1.num;
                this.Cg_Amount();
            } else {
                this.formObj.TaxesAndCharges = null;
            }
        },
        //值改变-计算总额及税额 
        Cg_Amount() {
            var Tax = this.formObj.TaxesAndCharges == null ? 0 : Number(this.formObj.TaxesAndCharges);//税率
            var NewTaxAmount = 0;
            var NewAmount = 0;
            this.formObj.itemTable.forEach(v => {
                if (this.formObj.IsIncludeTax) {
                    //金额
                    var rowAmount = Number(v.Qty) * Number(v.Rate);
                    if (Tax == 0 || rowAmount == 0) {
                        v.TotalTaxesAndCharges = 0;
                    }
                    else {
                        var n_TotalTaxesAndCharges = rowAmount - (rowAmount / ((Tax * 0.01) + 1));
                        v.TotalTaxesAndCharges = n_TotalTaxesAndCharges.toFixed(2)
                    }
                    v.Amount = rowAmount;
                }
                else {
                    //金额
                    var rowAmount = Number(v.Qty) * Number(v.Rate);
                    if (Tax == 0 || rowAmount == 0) {
                        v.TotalTaxesAndCharges = 0;
                    }
                    else {
                        var n_TotalTaxesAndCharges = Tax * 0.01 * rowAmount;
                        v.TotalTaxesAndCharges = n_TotalTaxesAndCharges.toFixed(2)
                    }
                    v.Amount = Number(v.TotalTaxesAndCharges) + rowAmount;
                }
                NewTaxAmount = Number(NewTaxAmount)+Number(v.TotalTaxesAndCharges);
                NewAmount += v.Amount;
            });
            this.formObj.Total = NewAmount;
            this.formObj.TotalTaxesAndCharges = NewTaxAmount.toFixed(2);
        },
        //值改变-行计算总额及税额 
        Cg_RowAmount(row) {
            var Tax = this.formObj.TaxesAndCharges == null ? 0 : Number(this.formObj.TaxesAndCharges);//税率
            if (this.formObj.IsIncludeTax) {
                var rowAmount = Number(row.Qty) * Number(row.Rate);
                if (Tax == 0) {
                    row.TotalTaxesAndCharges = 0;
                }
                else {
                    var n_TotalTaxesAndCharges = rowAmount - (rowAmount / ((Tax * 0.01) + 1));
                    row.TotalTaxesAndCharges = n_TotalTaxesAndCharges.toFixed(2)
                }
                row.Amount = rowAmount;
            }
            else {
                var rowAmount = Number(row.Qty) * Number(row.Rate);
                if (Tax == 0) {
                    row.TotalTaxesAndCharges = 0;
                }
                else {
                    var n_TotalTaxesAndCharges = Tax * 0.01 * rowAmount;
                    row.TotalTaxesAndCharges = n_TotalTaxesAndCharges.toFixed(2)
                }
                row.Amount = row.TotalTaxesAndCharges + rowAmount;
            }
            this.Cg_Amount();
        },
        //点击行变化
        handleCurrentChange(currentRow, oldCurrentRow) {
            if (currentRow.index == this.formObj.itemTable.length - 1) {
                this.addrow();
            }
            if (currentRow.Id == '') {
                this.$set(currentRow, 'isEgdit', true);
                if (oldCurrentRow != null && oldCurrentRow != undefined) {
                    this.$set(oldCurrentRow, 'isEgdit', false);
                }
                return;
            } else {
                this.$set(currentRow, 'isEgdit', true);
                if (oldCurrentRow != null && oldCurrentRow != undefined) {
                    this.$set(oldCurrentRow, 'isEgdit', false);
                }
            }
        },
        //添加行号
        tableRowClassName({ row, rowIndex }) {
            // 把每一行的索引放进row
            row.index = rowIndex
        },
        //列头加星号
        starAdd(obj) {
            if (obj.columnIndex === 2 || obj.columnIndex === 3 || obj.columnIndex === 5 || obj.columnIndex === 6 || obj.columnIndex === 7) {
                return 'star';
            }
        },
    }
}
</script>