import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { PurchaseContractItemIList, PurchaseContractItemIById,PurchaseContractItemIAdd, PurchaseContractItemIEdit, PurchaseContractItemIDel} from "@/api/Purchase/PurchaseContractItemApi";
//显示列表查询
export const PurchaseContractItemIList = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPurchaseContractItems",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const PurchaseContractItemIById = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPurchaseContractItem",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const PurchaseContractItemIAdd = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/PostPurchaseContractItem",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const PurchaseContractItemIEdit = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/PutPurchaseContractItem",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const PurchaseContractItemIDel = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/DeletePurchaseContractItem",
        method: "delete",
        params: {
            id
        }
    })
}



